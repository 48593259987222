<script setup lang="ts">
import { map } from 'rxjs/operators'
import { showToast } from 'vant'
import { PlayerApi } from '~/net/apis/player_api'
import { CacheApi } from '~/net/apis/cache_api'
import { setViewStorage, deepEqual } from '~/utils/utils'

const router = useRouter()
const videoId = useRouteParam<string>('id')
const videoUrl = ref('')

const relateGuessData = useObservable(
  from(
    PlayerApi.relatedGuessVideo({
      id: videoId.value
    })
  ).pipe(map(({ data }) => data))
)
/// 猜你喜欢
const guessList = computed(() => relateGuessData.value?.guessVideoList || [])
/// 影片相关
const relateList = computed(() => relateGuessData.value?.relateVideoList || [])
const videoExtend = useObservable(
  from(
    PlayerApi.query({
      videoId: videoId.value
    })
  ).pipe(map(({ data }) => data))
)
const categoryTagNameList = computed(() => videoExtend.value?.javVideoDTO?.categoryTagNameList || [])
const categoryTagIdList = computed(() => videoExtend.value?.javVideoDTO?.categoryTagIdList || [])
// 全部 日本 欧美 中国 韩国 其他
const nationFilter = [
  { index: 0, title: '全部', flag: '', isSelected: true },
  { index: 1, title: '日本', flag: '1', isSelected: false },
  { index: 2, title: '欧美', flag: '2', isSelected: false },
  { index: 3, title: '中国', flag: '3', isSelected: false },
  { index: 4, title: '韩国', flag: '4', isSelected: false },
  { index: 4, title: '其他', flag: '99', isSelected: false }
]
const nation = computed(() =>
  nationFilter.find((r) => Number(r.flag) === (videoExtend.value?.javVideoDTO?.actressNationality || 99))
)
const like = ref(false)
async function handleLikeToggle() {
  await PlayerApi.collect({ videoId: videoId.value })
  like.value = !like.value
}
async function cacheVideoo() {
  const { data } = await CacheApi.cacheVideo({ videoId: videoId.value })
  showToast('正在下载')
  window.open(data, '_blank')
}

watch(
  () => videoExtend.value,
  (newVal, oldVal) => {
    console.log('videoExtend>>>>>>',newVal, oldVal)
    if (!deepEqual(newVal, oldVal))
    {
      init()
    }
  }
)
function init() {
  if (videoExtend.value && videoExtend.value.javVideoDTO) {
    setViewStorage('browsHistory', { ...videoExtend.value?.javVideoDTO })
  }
}
</script>

<template>
  <div>
    <VideoPlayer :url="videoUrl" class="mb-5 lg:mb-0 lg:w-40% lg:flex-1" />

    <div class="px-15px">
      <p ct-E8E8E8 text="15px">{{ videoExtend?.javVideoDTO?.videoName }}</p>

      <div class="">
        <div flex items-center>
          <span ct-e8e8e8>影片信息</span>
          <span>热辣评论</span>
        </div>

        <div id="video-info">
          <a flex ct-e8e8e8 class="text-12px">
            <span>播放:&nbsp;</span>
            <span>{{ videoExtend?.javVideoDTO?.playCountTotal }}w</span>
          </a>

          <a mt-6px flex ct-e8e8e8 class="text-12px">
            <span>番号:&nbsp;</span>
            <span>{{ videoExtend?.javVideoDTO?.videoNo }}</span>
          </a>

          <a mt-6px flex ct-e8e8e8 class="text-12px">
            <span>国籍:&nbsp;</span>
            <span ct-FD5B03>{{ nation?.title }}</span>
          </a>

          <a mt-6px flex ct-e8e8e8 class="text-12px">
            <span>更新日期:&nbsp;</span>
            <span>{{ videoExtend?.javVideoDTO?.initUpdatedAt }}</span>
          </a>
        </div>

        <div id="keys-chip-box" mt-20px flex flex-wrap items-center>
          <a

            v-for="(item, index) in categoryTagNameList"

            :key="index"

             mb-12px mr-12px h-22px w-auto flex items-center justify-center rd-3px px-12px text-12px bg-373c59 ct-e8e8e8
          >
            {{ item }}
          </a>
        </div>

        <!-- <div flex items-center text-12px ct-E8E8E8>
          <div
            i-carbon:favorite-filled
            mr-8px
            text-20px
            :class="[like ? `ct-FD9403` : 'ct-rueGray']"
            @click="handleLikeToggle"
          />
          <span>2.6w</span>
          <div i-ph:download-simple-bold ml-2xl text-14px @click="cacheVideoo" />
        </div> -->

        <CommonCellView21 pb-5 title="相关影片" :list="guessList">
          <template #="{ item }">
            <HorizontalCard1 :item="item" />
          </template>
        </CommonCellView21>
        <!-- <CommonCellView2 v-for="items in guessList" :key="items.id" :title-data="items" /> -->
      </div>
    </div>
  </div>
</template>

<style scoped></style>
